import { Observable, from, of } from "rxjs";

export function isPromise(value: any): value is PromiseLike<any> {
    return !!value && typeof value.subscribe !== 'function' && typeof value.then === 'function';
}

export function toObservable<T>(value: T | Promise<T>): Observable<T> {
    return isPromise(value) ? from(value) : of(value);
}

export function assignDefined(target, ...sources) {
    for (const source of sources) {
        for (const key of Object.keys(source)) {
            const val = source[key];
            if (val !== undefined) {
                target[key] = val;
            }
        }
    }
    return target;
}
