<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="select-none">
  <mat-tree-node *matTreeNodeDef="let item" matTreeNodeToggle>
    <li class="mat-tree-node" [class.selected]="item.e.selected"
        [class.last]="item === last" (click)="select($event, item.e)" (dblclick)="dblclick(item.e)">
      <button mat-icon-button disabled></button>
      <mat-icon>{{entityIcon(item.e)}}</mat-icon>
      <span>{{item.name}}</span>
      <span *ngIf="item.price" class="price">{{item.price}}&#8381;</span>
    </li>
  </mat-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let item; when: hasNestedChild">
    <li>
      <div class="mat-tree-node" [class.selected]="item.e.selected" [class.last]="item === last"
        (click)="select($event, item.e)" (dblclick)="dblclick(item.e)">
        <button mat-icon-button (click)="toggleItem(item)">
          <mat-icon class="mat-icon-rtl-mirror">
            {{isExpanded(item) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <mat-icon>{{entityIcon(item.e)}}</mat-icon>
        <span>{{item.name}}</span>
        <span *ngIf="item.price" class="price">{{item.price}}&#8381;</span>
      </div>
      <ul [class.invisible]="!isExpanded(item)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
