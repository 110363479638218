import { EventEmitter, Injectable } from '@angular/core';
import { DialogService } from 'app/dialogs/services/dialog.service';
import { DownloadComponent } from '../download/download.component';
import { FileItem, FilesService } from 'app/shared/files.service';
import { saveAsDialog } from 'app/shared/filesaver';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

export interface IUploadOptions {
  url: string;
  method: 'post' | 'put';
  file?: File;
  headers?: { [key: string]: string };
  formData?: FormData;
}

@Injectable()
export class TdFileService {

  constructor(private dialog: DialogService, private filesService: FilesService) { }

  openDownloadDialog(file: FileItem, format?: string, rootUid?: string, materials = false, saveAs = true) {
    let result = new EventEmitter();
    let dialogRef = this.dialog.open(DownloadComponent, {
      minWidth: "40%",
      disableClose: true,
      data: file
    });
    dialogRef.componentInstance.downloadResponse$ = this.filesService.
      downloadFileWithProgress(file, format, rootUid, materials).pipe(map(resp => {
        if (resp && resp.blob) {
          resp.progress = 100;
          if (saveAs) {
            saveAsDialog(resp.blob, file.name + '.' + (format || '.wpm'));
          }
          result.next(resp.blob);
          result.complete();
          setTimeout(() => dialogRef.close(true), 1000);
        }
        if (resp && resp.error) {
          dialogRef.disableClose = false;
          result.error(resp.error);
        }
        return resp;
      }));
    dialogRef.afterClosed().subscribe(ok => {
      if (!ok) {
        dialogRef.componentInstance.downloadResponse$ = of(undefined);
      }
    });
    return result;
  }
}
