import { Component, OnInit } from '@angular/core';
import { FileItem, AuthService } from 'app/shared';
import { EmbedService } from 'embed/embed.service';
import { SystemService } from 'app/shared/system.service';

@Component({
  selector: 'app-embed-home',
  templateUrl: './embed-home.component.html',
  styleUrls: ['./embed-home.component.scss']
})
export class EmbedHomeComponent implements OnInit {

  constructor(
    public embed: EmbedService,
    private system: SystemService
  ) {

  }

  lastProjectId = this.embed.getLastProjectId();

  ngOnInit() {
  }

  appName() {
    return this.system.config.applicationName || 'WebPlanner';
  }

  afterProjectCreate(f: FileItem) {
    this.embed.goToProject(f.id);
  }
}
