<div class="app-search-input">
  <mat-form-field class="app-search-input-field"
                  [class.mat-hide-underline]="!showUnderline"
                  floatLabel="never">
    <input matInput
            #searchElement
            type="search"
            [(ngModel)]="value"
            [placeholder]="placeholder"
            (blur)="handleBlur()"
            (search)="stopPropagation($event)"
            (keyup.enter)="handleSearch($event)"/>
  </mat-form-field>
  <button mat-icon-button
          class="app-search-input-clear"
          type="button"
          [@searchState]="(searchElement.value ?  'show' : (isRTL ? 'hide-left' : 'hide-right'))"
          (click)="clearSearch()">
    <mat-icon>{{clearIcon}}</mat-icon>
  </button>
</div>