<div class="app-search-box">
  <button mat-icon-button type="button" class="app-search-icon" (click)="searchClicked()">
    <mat-icon *ngIf="searchVisible && !alwaysVisible">{{backIcon}}</mat-icon>
    <mat-icon *ngIf="!searchVisible || alwaysVisible">{{searchIcon}}</mat-icon>
  </button>
  <app-search-input #searchInput
                   [@inputState]="alwaysVisible || searchVisible"
                   [debounce]="debounce"
                   [(ngModel)]="value"
                   [showUnderline]="showUnderline"
                   [placeholder]="placeholder"
                   [clearIcon]="clearIcon"
                   (searchDebounce)="handleSearchDebounce($event)"
                   (search)="handleSearch($event)"
                   (clear)="handleClear(); toggleVisibility()"
                   (blur)="handleBlur()">
  </app-search-input>
</div>