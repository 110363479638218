import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { saveAsDialog } from 'app/shared/filesaver';
import { dataURItoFile, FileItem, FilesService } from 'app/shared';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { concatMap, delay, map, mapTo, startWith, tap } from 'rxjs/operators';
import { Lightbox } from 'ng-gallery/lightbox';
import { Gallery } from 'ng-gallery';
import { DialogService } from 'app/dialogs/services/dialog.service';

export interface PhotoDataData {
  image$: Observable<string>;
  file: FileItem;
}

@Component({
  selector: 'app-project-photo',
  templateUrl: './project-photo.component.html',
  styleUrls: ['./project-photo.component.scss']
})
export class ProjectPhotoComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: PhotoDataData,
    private files: FilesService, private galleryService: Gallery,
    private lightBox: Lightbox, private dialog: DialogService) { }

  imagesChanged = new BehaviorSubject(true);
  images$ = this.imagesChanged.pipe(
    concatMap(_ => this.files.getFileImages(this.data.file.id)),
    map(list => list.length ? list : undefined)
  );

  saveImage(image: string) {
    let fileName = this.data.file.name + '.png';
    let file = dataURItoFile(image, fileName);
    saveAsDialog(file, fileName);
  }

  get gallery() {
    let galleryId = 'p' + this.data.file.id;
    return this.galleryService.ref(galleryId);
  }

  removeImage(image: FileItem, index: number) {
    this.dialog.openConfirm({message: $localize`Are you sure to remove image?`}).afterClosed().subscribe(ok => {
      if (ok) {
        this.files.removeFile(image).subscribe(
          _ => {
            this.imagesChanged.next(true);
            this.gallery.remove(index);
          }, e => this.dialog.showError(e));
      }
    });
  }

  addImage$: Observable<string>;

  addImageToProject(image: string) {
    this.addImage$ = this.files.addFileImage(this.data.file.id, image).pipe(
      startWith($localize`Adding image to project`),
      mapTo($localize`Image added to project`),
      tap(_ => this.imagesChanged.next(true))
    );
  }

  @ViewChild('thumbTemplate', { static: true }) thumbTemplate!: TemplateRef<any>;

  viewImages(images: FileItem[]) {
    this.galleryService.destroyAll();
    let galleryId = 'p' + this.data.file.id;
    let gallery = this.galleryService.ref(galleryId);
    if (!this.data.file.readOnly) {
      gallery.setConfig({ thumbTemplate: this.thumbTemplate });
    }
    for (let image of images) {
      gallery.addImage({
        id: image.id,
        src: this.files.getImageUrl(image),
        thumb: this.files.getImagePreviewUrl(image)
      });
    }
    this.lightBox.open(0, galleryId, {});
  }

  print(image: string) {
    let features = 'top=0,left=0,height=100%,width=auto';
    let printWindow = window.open('', '_blank', features);
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>${this.data.file.name}</title>
          <style>img {
            max-width: 100%;
            max-height: 100%;
          }</style>
          <style type="text/css" media="print">
            @page { size: landscape; }
          </style>
        </head>
        <body><img src="${image}"></body>
      </html>`);
    printWindow.document.close();
    fromEvent(printWindow, 'load').pipe(delay(100)).subscribe(_ => {
      printWindow.print();
      printWindow.close();
    });
  }
}
