import {LetDirective as LetDirective} from './let.directive';

interface LetContextWithImplicit<T> {
    $implicit: T;
  }

/**
 * @internal
 */
export class LetContext<T> implements LetContextWithImplicit<T> {
    constructor(private readonly internalDirectiveInstance: LetDirective<T>) {}

    get $implicit(): T {
        return this.internalDirectiveInstance.ngLet;
    }

    get ngLet(): T {
        return this.internalDirectiveInstance.ngLet;
    }
}
