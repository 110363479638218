import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ProgressDialog {
  title: string;
  status: string;
  progress: number;
  cancel: () => unknown;
  log(data: string);
}

export type ProgressFunction = (dialog: ProgressDialog) => Promise<unknown>;

@Component({
  selector: 'app-app-progress-dialog',
  templateUrl: './app-progress-dialog.component.html',
  styleUrls: ['./app-progress-dialog.component.scss']
})
export class AppProgressDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AppProgressDialogComponent>,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private runner: ProgressFunction) {

    }

  error = '';
  logs: string[] = [];

  private _title = $localize`Running...`;
  get title() {
    return this._title;
  }
  set title(value) {
    this._title = value;
    this.cd.markForCheck();
  }

  private _status = '';
  get status() {
    return this._status;
  }

  set status(value) {
    this._status = value;
    this.cd.markForCheck();
  }

  private _progress = 0;

  get progress() {
    return this._progress;
  }

  set progress(value) {
    this._progress = value;
    this.cd.markForCheck();
  }

  private _cancel: () => unknown;

  get cancel() {
    return this._cancel;
  }

  set cancel(value) {
    this._cancel = value;
    this.cd.markForCheck();
  }

  canceled = false;

  ngOnInit(): void {
    this.runner(this).then(value => {
      this.dialogRef.close(value);
      this.cd.markForCheck();
    }).catch(error => {
      if (error instanceof HttpErrorResponse) {
        this.error = error.message;
      } else if (error instanceof Error) {
        this.error = error.message;
      } else {
       this.error = error.toString();
      }
      this.cancel = undefined;
      this.status = '';
      this.cd.markForCheck();
    });
  }

  log(data: string) {
    this.logs.push(data);
    this.cd.markForCheck();
  }

  stop() {
    if (this.cancel) {
      this.cancel();
      this.canceled = true;
      this.cd.markForCheck();
    }
  }

  close() {
    this.dialogRef.close(new Error(this.error));
  }
}
