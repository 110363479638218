import { Component, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';
import { Entity } from 'modeler/designer';
import { EditorProperty, PropertyType, PropertyValue, ValueContainer, LabeledVariant } from 'modeler/property-editor';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
  selector: 'tr[app-property-editor-table-row]',
  templateUrl: './property-editor-table-row.component.html',
  styleUrls: ['./property-editor-table-row.component.scss']
})
export class PropertyEditorTableRowComponent {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {

  }

  _property: EditorProperty = new EditorProperty(PropertyType.Text);

  @HostBinding('class.hidden') hidden: boolean;

  private oldCssClass = '';

  @Input() set property(p: EditorProperty) {
    this._property = p;
    if (this.oldCssClass) {
      this.renderer.removeClass(this.elementRef.nativeElement, this.oldCssClass);
      this.oldCssClass = '';
    }
    if (p.cssClass) {
      this.renderer.addClass(this.elementRef.nativeElement, p.cssClass);
      this.oldCssClass = p.cssClass;
    }
    this.updateValue();
  };

  @Input() set selectedItems(s: Entity[]) {
    this._selectedItems = s;
    this.updateValue();
  };

  get type() {
    return this._property.type;
  }

  set variants(vs: Array<LabeledVariant | string | number>) {
    this._variants = vs.map(v => {
      if (typeof v === 'string' || typeof v === 'number') {
        let label = v.toString();
        return new LabeledVariant(label, v);
      }
      return v;
    })
  }

  _variants?: LabeledVariant[];

  private getValueFromSelection(selection: Entity[]) {
    if (selection) {
      let value: PropertyValue | Observable<PropertyValue> | Promise<PropertyValue>;
      if (this._property.getter) {
        value = this._property.getter(selection[0]);
        for (let i = 1; i < selection.length; i++) {
          let curValue = this._property.getter(selection[i]);
          if (curValue === null) {
            return null;
          }
          if (curValue instanceof ValueContainer && value instanceof ValueContainer) {
            if (curValue.readonly !== value.readonly) {
              value.readonly = true;
            }
            if (curValue.value !== value.value) {
              value.value = '';
            }
          }
          if (curValue !== value) {
            value = '';
          }
        }
      } else if (this._property.multiGetter) {
        value = this._property.multiGetter(selection);
      }
      if (typeof value === 'string' || typeof value === 'number' || value instanceof ValueContainer) {
        value = of(value);
      } else if (value instanceof Promise) {
        value = from(value);
      }
      return this.convertToValueContainer(value);
    }
    return null;
  }

  private updateValue() {
    let newValue = this.getValueFromSelection(this._selectedItems);
    if (this._property.variants) {
      if (this._property.variants instanceof Array) {
        this.variants = this._property.variants;
      } else if (typeof this._property.variants === 'function') {
        this.variants = this._property.variants();
      }
    }
    this.hidden = newValue === null;
    this.value$ = newValue;
  }

  private convertToValueContainer(value$: Observable<PropertyValue> | null): Observable<ValueContainer> | null {
    let result: Observable<ValueContainer> = null;
    if (value$) {
      result = value$.pipe(map(v => {
        if (v instanceof ValueContainer) {
          return v;
        }
        return new ValueContainer(v);
      }));
    }
    return result;
  }

  public PropertyType = PropertyType;

  public value$: Observable<ValueContainer> | null;

  public _selectedItems: Entity[];


  setValue(value: string | number) {
    this._property.setter?.(value, this._selectedItems);
  }

  onButtonClick() {
    this._property.button.click();
  }

}
