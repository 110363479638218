<h1 mat-dialog-title>{{title}}</h1>
<mat-dialog-content>
    <div *ngFor="let log of logs" class="log-item">{{log}}</div>
</mat-dialog-content>
<div *ngIf="status" class="push-bottom">{{status}}</div>
<mat-progress-bar *ngIf="!error" [mode]="progress ? 'determinate' : 'indeterminate'" [value]="progress"></mat-progress-bar>
<app-message *ngIf="error" color="warn" icon="error" class="push-top">{{error}}</app-message>
<mat-dialog-actions align="end">
    <button *ngIf="cancel" mat-button color="primary" (click)="stop()" i18n>CANCEL</button>
    <button *ngIf="error" mat-button color="primary" (click)="close()" i18n>CLOSE</button>
</mat-dialog-actions>