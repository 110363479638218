import { Injectable, ErrorHandler } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { environment } from "environments/environment";
import { commit } from "git-version";

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

export let sentryErrorHandlers = [];

export function initSentry() {
  if (environment.raven) {
    Sentry.init({
      dsn: environment.raven,
      release: commit,

      tracesSampleRate: 0.3,
      enableTracing: true,

      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay({
          maskAllText: false,
          maskAllInputs: false,
        }),
      ],
    });
    sentryErrorHandlers = [
      { provide: ErrorHandler, useClass: SentryErrorHandler },
    ];
  }
}
