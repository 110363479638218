import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { CustomButton } from "../services/dialog.service";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class AppConfirmDialogComponent implements AfterViewInit {
  @ViewChild("closeBtn") private closeBtn: MatButton;

  title: string;
  message: string;
  customButtons?: CustomButton[];

  ngAfterViewInit() {
    this.closeBtn.focus();
  }
}
