<div class="app-json-formatter-wrapper">
  <a
    class="app-key"
    [class.app-key-node]="hasChildren()"
    [class.app-key-leaf]="!hasChildren()"
    [tabIndex]="isObject() ? 0 : -1"
    (keydown.enter)="toggle()"
    (click)="toggle()"
  >
    <mat-icon class="app-node-icon" *ngIf="hasChildren()">
      {{ open ? 'keyboard_arrow_down' : isRTL ? 'keyboard_arrow_left' : 'keyboard_arrow_right' }}
    </mat-icon>
    <span *ngIf="key" class="key">{{ key }}:</span>
    <span class="value">
      <span [class.app-empty]="!hasChildren()" *ngIf="isObject()" [matTooltip]="getPreview()" matTooltipPosition="after">
        <span class="app-object-name">{{ getObjectName() }}</span>
        <span class="app-array-length" *ngIf="isArray()">[{{ data.length }}]</span>
      </span>
      <span *ngIf="!isObject()" [class]="getType(data)">{{ getValue(data) }}</span>
    </span>
  </a>
  <div class="app-object-children" [@collapse]="!(hasChildren() && open)">
    <ng-template let-key ngFor [ngForOf]="children">
      <app-json-formatter [key]="key" [data]="data[key]" [levelsOpen]="levelsOpen - 1"></app-json-formatter>
    </ng-template>
  </div>
</div>
