import { Component } from '@angular/core';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss' ],
})
export class AppAlertDialogComponent {
  title: string;
  _message: unknown;
  isObject = false;
  levelsOpen = 1;

  set message(value: unknown) {
    this._message = value;
    this.isObject = false;
    if (typeof value === 'object' && value !== null) {
      this.isObject = true;
      let levels = value['__wpLevelsOpen'];
      if (typeof levels === 'number') {
        this.levelsOpen =  levels;
        delete this.message['__wpLevelsOpen'];
      }
    }
  }

  get message() {
    return this._message
  }
}
