<div *ngIf="type; let t" fxFlex="none" fxLayout="row" fxLayoutAlign="start center" [style.min-height.px]="64">
  <mat-icon svgIcon="properties" class="push"></mat-icon>
  <h4 *ngIf="t === 1" i18n>{count, plural, =1 {1 item selected} other {{{count}} items selected}}</h4>
  <h4 *ngIf="t === 2" i18n>Floor plan</h4>
  <h4 *ngIf="t === 3" i18n>Wall</h4>
  <h4 *ngIf="t === 4" i18n>Room</h4>
  <span fxFlex></span>
  <button mat-icon-button (click)="close.next()" matTooltip="Close"
    i18n-matTooltip>
    <mat-icon>close</mat-icon>
  </button>
</div>
<table>
  <tbody>
    <ng-container *ngFor="let property of properties">
      <tr *ngIf="!asGroup(property)" app-property-editor-table-row [property]="property"
        [selectedItems]="_selectedItems"></tr>
      <ng-container *ngIf="asGroup(property) as group">
        <tr app-property-editor-table-row *ngFor="let prop of group.items" [property]="prop"
          [selectedItems]="_selectedItems"></tr>
      </ng-container>
    </ng-container>
  </tbody>

  <ng-container *ngIf="modelPropsAndParams$ | async; let pr">
    <tbody props-and-params class="modelProperties" [propsAndParams]="pr" [selectedItems]="selectedItems" [materialCache]="materialCache"
      [ds]="ds" [propertyLoader]="propertyLoader">
    </tbody>
  </ng-container>
</table>

  <ng-container *ngIf="elementsPropsAndParams$ | async; let pr">
    <mat-expansion-panel *ngIf="pr.props && pr.props.length > 0 || pr.params && pr.params.length > 0" fxFlex="none">
      <mat-expansion-panel-header>
        <mat-panel-title i18n>Elements properties</mat-panel-title>
      </mat-expansion-panel-header>
      <table>
        <tbody props-and-params class="elementsProperties" [propsAndParams]="pr" [selectedItems]="selectedItems" [materialCache]="materialCache"
          [ds]="ds" [propertyLoader]="propertyLoader">
        </tbody>
      </table>
    </mat-expansion-panel>
  </ng-container>
