import { Component, OnInit, Inject, ViewChild, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MaterialUnit, FileItem, OrderInfo, OrderService, FilesService, AuthService, ClientInfo } from '../../shared';
import { PriceElement,  EstimateService } from '../estimate';
import { Observable } from 'rxjs';
import { concatMap, filter, map, tap } from 'rxjs/operators';
import { OrderSenderComponent } from '../order-sender/order-sender.component';
import { OrderEditorComponent } from '../orders/order-editor/order-editor.component';
import { SystemService, ReportType, Report } from 'app/shared/system.service';
import { compileXml, compileHtml } from '../print-dialog/template-compiler';
import { PlannerScriptInterface } from '../planner.script';

export interface SpecificationDetails {
  file: FileItem,
  estimate: EstimateService,
  order: Observable<OrderInfo>,
  scriptInterface: PlannerScriptInterface;

  updateClient: (client: ClientInfo) => void;
};

@Component({
  selector: 'app-specification',
  templateUrl: './specification.component.html',
  styleUrls: ['./specification.component.scss']
})
export class SpecificationComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: SpecificationDetails,
    private dialogs: MatDialog, private firm: OrderService,
    private system: SystemService, public auth: AuthService,
    private dialogRef: MatDialogRef<SpecificationComponent>,
    private files: FilesService) {
      this.dataSource = new MatTableDataSource(data.estimate.gatherElements());
    }

  ui = this.data.scriptInterface.ui.specification;

  xmlTemplates$ = this.system.getTemplates().pipe(
    map(list => list.filter(t => t.type === ReportType.Xml)),
    filter(list => list.length > 0)
  );
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['sku', 'name', 'price', 'count', 'cost'];
  versionColumns = ['name', 'date'];
  unit = MaterialUnit;
  orderConfirmation = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dialogRef.updateSize('70vw');
  }

  publishProjectPrice() {
    this.files.setPrice(this.data.file, this.data.estimate.price).subscribe();
  }

  makeOrder(template?: Report) {
    if (!template) {
      return;
    }
    this.dialogRef.close(false);
    this.data.order.pipe(
      concatMap(order => {
        let data = order || { id: this.data.file.id};
        let dialog = this.dialogs.open(OrderEditorComponent, { minWidth: '50%', data });
        return dialog.afterClosed();
      }),
      filter(v => v),
      concatMap((order: OrderInfo) => this.firm.setOrder(order.id, order)),
      tap(order => this.data.updateClient(order.client)),
      concatMap(_ => this.system.getTemplate(template.id))
    ).subscribe(report => this.saveOrderXml(report));
    this.close();
  }

  private async saveOrderXml(report: Report) {
    this.publishProjectPrice();
    let xml = await compileXml(report.template, this.data.scriptInterface);
    let fileName = await compileHtml(report.name, this.data.scriptInterface);
    this.data.scriptInterface.ui.save(xml, fileName + '.xml');
  }

  sendOrder() {
    this.dialogs.open(OrderSenderComponent, { data: this.data, minWidth: '40%'});
  }

  getRowClasses(e: PriceElement) {
    let classes = ['wp-estimate-element'];
    if (e.elements.length > 0) {
      classes.push('section');
    }
    if (e.className) {
      let escaped = e.className.trim().replace(/\s/g, '_');
      classes.push(escaped);
    }
    return classes;
  }

  close() {
    this.dialogRef.close();
  }
}
