import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from 'app/shared';
import { of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { SystemService } from './shared/system.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate() {
    return this.auth.isAdmin.pipe(
      filter(val => val !== undefined),
      map(v => v ? true : this.router.createUrlTree(['']))
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService) {}

  canActivate() {
    return this.auth.isAuthenticated.pipe(filter(val => val !== undefined));
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate();
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthStatusGuard implements CanActivate, CanActivateChild {
  constructor(private auth: AuthService) {}

  canActivate() {
    return this.auth.isAuthenticated.pipe(
      filter(val => val !== undefined),
      map(_ => true)
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate();
  }
}

@Injectable({
  providedIn: 'root',
})
export class ShortProjectLinkGuard implements CanActivate {
  constructor(private router: Router, private system: SystemService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    let shortLink = route.queryParamMap.keys[0];
    let projectId = route.params['id'];
    if (projectId) {
      if (shortLink) {
        return this.system.restoreUrl(shortLink).pipe(
          map(full => this.router.parseUrl(`/project/${projectId}?${full}`)),
          catchError(_ => of(this.router.createUrlTree(['project', projectId])))
        );
      }
      return this.router.createUrlTree(['project', projectId]);
    }
    return this.router.createUrlTree(['notfound']);
  }
}
