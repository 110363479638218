<td>{{_property.name}}</td>
<ng-container *ngIf="value$ | async as valueContainer">
  <td *ngIf="!valueContainer.readonly" [ngSwitch]="type" class="pad-sm">
    <ng-container *ngLet="valueContainer.value as value">
      <mat-form-field *ngSwitchCase="PropertyType.Number" appearance="outline">
        <input matInput autocomplete="off" align="end" [value]="value | float" (change)="setValue($event.target.value)">
        <span *ngIf="_property.suffix" matSuffix>{{_property.suffix}}</span>
      </mat-form-field>
      <mat-form-field *ngSwitchCase="PropertyType.Text" appearance="outline">
        <input matInput type="text" align="end" [value]="value" (change)="setValue($event.target.value)">
        <span *ngIf="_property.suffix" matSuffix>{{_property.suffix}}</span>
      </mat-form-field>
      <app-color-picker *ngSwitchCase="PropertyType.ColorPicker" [selectedColor]="value" (selected)="setValue($event)"
      [colorIcon]="true" [hideButtons]="true" [hideUsedColors]="true"></app-color-picker>
      <mat-form-field *ngSwitchCase="PropertyType.Select">
        <mat-select [value]="value" (selectionChange)="setValue($event.value)">
          <mat-option *ngFor="let option of _variants" [value]="option.value">{{option.label}}</mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngSwitchCase="PropertyType.Button">
        <div *ngIf="value" class="push-sm">{{value}}</div>
        <button mat-raised-button (click)="onButtonClick()">{{_property.button.label}}</button>
      </ng-container>
    </ng-container>
  </td>
  <td *ngIf="valueContainer.readonly">
    <div class="push">{{valueContainer.value | float}}
      <span *ngIf="_property.suffix" class="wp-property-suffix">{{_property.suffix}}</span>
    </div>
  </td>

</ng-container>