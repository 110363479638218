import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Entity } from "./designer";

export enum PropertyType {
  Text = 1,
  Number,
  Select,
  ColorPicker,
  Button,
  Group
}

export class ValueContainer {
  constructor(v: string | number, readonly = false) {
    this.value = v;
    this.readonly = readonly;
  }
  value: number | string;
  readonly = false;
}


export type PropertyValue = number | string  | ValueContainer;

type PublicPropertyParams = Omit<EditorProperty, 'type'>;

export class LabeledVariant {
  constructor(label: string, value: number | string) {
    this.label = label;
    this.value = value;
  }
  label: string;
  value: string | number;
}

export type PropertyVariants = Array<LabeledVariant | string | number>;

export class EditorProperty {
  constructor(public type: PropertyType, public name?: string) {}
  cssClass?: string;
  getter?: (entity: Entity) => PropertyValue | Observable<PropertyValue> | Promise<PropertyValue>;
  multiGetter?: (entities: Entity[]) => PropertyValue | Observable<PropertyValue> | Promise<PropertyValue>;
  setter?: (value: number | string, entities?: Entity[]) => void;
  suffix?: string;
  variants?: PropertyVariants | (() => PropertyVariants);
  button?: {
    click: () => void;
    label: string;
  };
}

export class EditorPropertyGroup extends EditorProperty {
  constructor(type: PropertyType, name?: string) {
    super(type, name);
  }
  items: EditorProperty[] = [];

  readOnlyValue(value: number | string) {
    let rvc = new ValueContainer(value, true);
    return rvc;
  }

  private addProperty(params: PublicPropertyParams, type: PropertyType) {
    const property = new EditorProperty(type, params.name);
    property.getter = params.getter;
    property.setter = params.setter;
    property.suffix = params.suffix;
    property.variants = params.variants;
    property.button = params.button;
    this.items.push(property);
    return property;
  }

  public addText(params: PublicPropertyParams) {
    const textProperty = this.addProperty(params, PropertyType.Text);
    return textProperty;
  }

  public addNumber(params: PublicPropertyParams) {
    const numberProperty = this.addProperty(params, PropertyType.Number);
    return numberProperty;
  }

  public addSelect(params: PublicPropertyParams) {
    const selectProperty = this.addProperty(params, PropertyType.Select);
    return selectProperty;
  }

  public addButton(params: PublicPropertyParams) {
    const buttonProperty = this.addProperty(params, PropertyType.Button);
    return buttonProperty;
  }

  public addColorPicker(params: PublicPropertyParams) {
    const colorProperty = this.addProperty(params, PropertyType.ColorPicker);
    return colorProperty;
  }

  public addGroup() {
    let propertyGroup = new EditorPropertyGroup(PropertyType.Group);
    this.items.push(propertyGroup);
    return propertyGroup;
  }

  public addReadonly(params: PublicPropertyParams) {
    let simpleGetter = params.getter;
    let readonlyGetter = (selected: Entity) => {
      let value = simpleGetter(selected);
      if (value !== null) {
        if (typeof(value) === 'string' || typeof(value) === 'number') {
          return this.readOnlyValue(value);
        }
        if (value instanceof Promise) {
          value = from(value);
        }
        if (value instanceof Observable) {
          return value.pipe(map(v => v instanceof ValueContainer ? v : this.readOnlyValue(v)));
        }
      }
      return null;
    }
    params.getter = readonlyGetter;
    let readonlyProperty = this.addProperty(params, PropertyType.Text);
    return readonlyProperty;
  }
}
