<div mat-dialog-title fxLayout="row" fxLayoutAlign="center center">
  <h3 class="inline-block flex-none push-right" i18n>Bulk replace</h3>
  <mat-form-field class="flex-auto">
    <mat-label i18n>Search</mat-label>
    <input matInput (keyup)="applyFilter($event)">
  </mat-form-field>
  <button class="flex-none" mat-icon-button matTooltip="Sort by name" matTooltipPosition="above" i18n-matTooltip (click)="sortList()">
    <mat-icon>sort</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <mat-nav-list>
    <ng-container *ngFor="let models of displayedModels">
      <mat-list-item (click)="replace(models)">
        <span matBadge={{models.length}} matBadgeOverlap="false">
          <span>{{models[0].name}}</span>
          <span *ngIf="models[0].type" class="push-left-sm">[{{models[0].type}}]</span>
        </span>
        <span class="flex-auto"></span>
        <button mat-raised-button color="primary" i18n>REPLACE</button>
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>
  </mat-nav-list>
</div>
