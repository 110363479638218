<div class="account-card-container">

    <mat-card>
        <mat-toolbar color="primary" i18n>Sign up</mat-toolbar>
        <mat-card-content>
            <form name="form" (ngSubmit)="f.form.valid && signup()" #f="ngForm" novalidate autocomplete="off" *ngIf="!success">
                <div>
                    <br />
                    <mat-form-field class="full-width">
                        <input matInput placeholder="User name" i18n-placeholder
                            type="text" (keyup)="errorMessages = []; errorMessage = ''"
                            pattern="[a-zA-Z0-9_\-]*" minlength="3" maxlength="30"
                            name="userName" [(ngModel)]="model.userName" #userName="ngModel" required>
                    </mat-form-field>
                    <small [hidden]="userName.valid || userName.pristine || !f.submitted" class="has-error" i18n>
                        Invalid user name. User name should contain only english letters. Minimal length is 3 symbols.
                    </small>
                    <br />
                    <br />
                    <mat-form-field class="full-width">
                        <input matInput placeholder="email" i18n-placeholder type="email"
                            (keyup)="errorMessages = []; errorMessage = ''"
                            name="email" [(ngModel)]="model.email" #email="ngModel"
                            email required>
                    </mat-form-field>
                    <small [hidden]="email.valid || email.pristine || !f.submitted" class="has-error" i18n>
                        Invalid email address
                    </small>
                    <br />
                    <br/>
                    <mat-form-field class="full-width">
                        <input matInput placeholder="password" i18n-placeholder
                            type="text" (keyup)="errorMessages = []; errorMessage = ''"
                            name="password" [(ngModel)]="model.password" #password="ngModel"
                            required minlength="6">
                    </mat-form-field>
                    <small [hidden]="password.valid || (password && !f.submitted)" class="has-error" i18n>
                        Password should contain at least 6 characters.
                    </small>
                    <br />
                    <div *ngIf="errorMessage != ''" class="has-error">
                        <small>{{ errorMessage }}</small>
                    </div>
                    <div *ngIf="errorMessages.length > 0" class="has-error">
                        <div *ngFor="let errorMsg of errorMessages">
                            <small>{{ errorMsg.description }}</small>
                        </div>
                    </div>
                    <br />
                </div>
                <div>
                    <button mat-raised-button color="primary" type="submit" class="full-width" i18n>Sign up</button>
                </div>
            </form>
            <app-message *ngIf="success && !admin" label="Info" i18n-label color="primary" icon="info"
                i18n>Registration succeeded! In a few minutes you will get verification email on {{model.email}}!</app-message>
            <app-message *ngIf="success && admin" label="Info" i18n-label class="admin-reg"
                color="primary" icon="info"
                i18n>You are registered as admin! You can login right now as {{model.userName}}!</app-message>
        </mat-card-content>
    </mat-card>

</div>