<div class="app-color-picker-selector">

    <div #block class="app-picker-selector"></div>
    <canvas #blockCanvas height="170" width="230" id="picker"></canvas>
    <div #blockPointer class="app-picker-position" style="top: 0px;left: 220px;"></div>


    <div #stripContainer class="app-colors-position" style="background-position-y: 0px;">
        <canvas #strip height="160" width="20" id="colors"></canvas>
    </div>
</div>

<div class="app-color-picker-selector-preview" [style.background]="selectedColor">

    <form [formGroup]="hexForm">
        <mat-form-field class="hex-input" floatLabel="always" [ngClass]="textClass">
            <input matInput placeholder="HEX" maxlength="7" formControlName="hexCode" [value]="selectedColor" />
        </mat-form-field>
    </form>

    <form [formGroup]="rgbForm">
        <mat-form-field class="rgb-input" floatLabel="always" [ngClass]="textClass">
            <input matInput type="number" placeholder="RGB" maxlength="3" formControlName="R" />
        </mat-form-field>
        <mat-form-field class="rgb-input" floatLabel="always" [ngClass]="textClass">
            <input matInput type="number" maxlength="3" formControlName="G" />
        </mat-form-field>
        <mat-form-field class="rgb-input" floatLabel="always" [ngClass]="textClass">
            <input matInput type="number" maxlength="3" formControlName="B" />
        </mat-form-field>
    </form>

</div>