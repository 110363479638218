<div class="app-color-picker-collection" role="listbox" aria-label="Select a color">
        <h3>{{ label }}</h3>

        <button mat-mini-fab *ngIf="transparent" class="app-color-picker-remove-color mat-elevation-z0" role="option" aria-label="transparent"
                (click)="setTransparent()">
                <div class="app-color-picker-transparent"></div>
        </button>

        <button *ngFor="let color of colors" mat-mini-fab class="mat-elevation-z0" role="option" [disabled]="!color || color === 'none'"
                [appColorPickerOption]="color" (click)="setColor(color)">
        </button>
</div>