import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { DialogService } from "app/dialogs/services/dialog.service";
import { Component, Input, ViewContainerRef, NgZone } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient } from "@angular/common/http";
import { ModelExplorerComponent } from "./model-explorer/model-explorer.component";
import {
  FileItem,
  FilesService,
  CatalogService,
  FileUploadOptions,
} from "app/shared";
import { loadModelInsertInfo } from "modeler/syncer";
import { tap, take, concatMap, filter, map } from "rxjs/operators";
import { saveAsDialog } from "app/shared/filesaver";
import { SystemService } from "app/shared/system.service";
import { of } from "rxjs";
import { compileXml } from "./print-dialog/template-compiler";
import { WebDesigner } from "modeler/webdesigner";
import {
  PointPicker,
  EntityPicker,
  PointPickerOptions,
  EntityPickerOptions,
} from "modeler/picker-tools";
import { ToolCancelled } from "modeler/designer-tool";
import { PlannerScriptInterface } from "./planner.script";
import {
  CatalogFolderSelectorComponent,
  FolderSelectorConfig,
} from "app/catalog-editor/catalog-folder-selector/catalog-folder-selector.component";
import { vec3 } from "modeler/geometry";
import { ProgressFunction } from "app/dialogs/app-progress-dialog/app-progress-dialog.component";

export enum UIElementType {
  MenuItem = 1,
  SubMenuTrigger,
  IconButton,
  IconMenuTrigger,
  ColorButton,
  ColorMenuTrigger,
  Button,
  ButtonMenuTrigger,
  Separator,
}

export interface SelectOptions {
  type?: string;
  search?: string;
  mode?: "trigram" | "ilike" | "like";
  notFoundMessage?: string;
  folders?: boolean;
}

export class UICollection {
  items: UIButton[] = [];

  get empty() {
    return this.items.length === 0;
  }

  add(text: string) {
    let button = new UIButton(text);
    this.items.push(button);
    return button;
  }

  addSeparator() {
    let separator = new UIButton();
    this.items.push(separator);
    return separator;
  }

  addIcon(icon: string) {
    let button = new UIButton("");
    button.icon = icon;
    this.items.push(button);
    return button;
  }

  remove(button: UIButton) {
    this.items = this.items.filter((b) => b !== button);
  }
}

export class UIButton extends UICollection {
  constructor(public text?: string) {
    super();
  }
  icon: string;
  tooltip: string;
  color?: "primary" | "accent" | "warn";
  disabled = false;
  class: string;
  visible = true;
  click?: () => void | Promise<any>;
}

export class RootUICollection extends UICollection {
  defaultItems = true;
}

export class PlannerUI {
  constructor(
    public ds: WebDesigner,
    private ngZone: NgZone,
    public _dialog: DialogService,
    private _vcr: ViewContainerRef,
    private _matIconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
    private fileService: FilesService,
    private catalogService: CatalogService
  ) {}

  toolbar = new RootUICollection();
  menu = new RootUICollection();
  popup = new RootUICollection();
  specification = new RootUICollection();

  private run<T>(fn: (...args: any[]) => T): T {
    return this.ngZone.run(fn);
  }

  alert(message: string) {
    // script can run code outside zone but components must be inside
    this.run(() => this._dialog.openAlert({ message }));
  }

  snack(message: string, duration?: number) {
    let config = duration ? { duration: duration * 1000 } : undefined;
    // script can run code outside zone but components must be inside
    this.run(() => this._dialog.snackBar.open(message, undefined, config));
  }

  progress(runner: ProgressFunction) {
    this.run(() => this._dialog.openProgress(runner))
      .afterClosed()
      .pipe(
        map((value) => {
          if (value instanceof Error) {
            throw value;
          }
          return value;
        })
      )
      .toPromise();
  }

  queryPoint(message: string, params: PointPickerOptions = {}) {
    if (params.orthoAxes) {
      params.orthoAxes = params.orthoAxes.map((v) => vec3.fromArray(v));
    }
    if (params.orthoBasis) {
      params.orthoBasis = vec3.fromArray(params.orthoBasis);
    }
    return this.run(() =>
      this.ds.tool.run(new PointPicker(this.ds, message, params))
    );
  }

  queryEntity(message: string, params?: EntityPickerOptions) {
    return this.run(() =>
      this.ds.tool.run(new EntityPicker(this.ds, message, params))
    );
  }

  save(content: Blob | string, fileName: string) {
    let bb = new Blob([content], { type: "application/xml;charset=utf-8" });
    saveAsDialog(bb, fileName, { autoBOM: true });
  }

  async selectModel(
    folderOrCatalog: number | string,
    options: SelectOptions = {}
  ) {
    let dialogRef = this._dialog.open(ModelExplorerComponent, {
      viewContainerRef: this._vcr,
      width: "50%",
      height: "60%",
    });
    let type = options.type;
    let search = options.search;
    let selector = dialogRef.componentInstance;
    if (type) {
      selector.fileFilter = (f: FileItem) => {
        if (f.insertInfo) {
          let info = loadModelInsertInfo(f.insertInfo);
          if (info) {
            return info.type === type;
          }
        }
        return true;
      };
    }
    if (search) {
      if (typeof folderOrCatalog === "number") {
        this.fileService.getFile(folderOrCatalog).subscribe((file) => {
          selector.searchFilesInCatalog(
            file.catalogId,
            folderOrCatalog,
            options
          );
        });
      } else {
        this.catalogService
          .findCatalogByName(folderOrCatalog)
          .subscribe((catalog) => {
            selector.searchFilesInCatalog(
              catalog.id,
              catalog.modelFolderId,
              options
            );
          });
      }
    } else {
      selector.selectFolder(folderOrCatalog);
    }
    return selector.fileSelected
      .pipe(
        tap((_) => dialogRef.close()),
        take(1)
      )
      .toPromise();
  }

  async selectFolder(
    options: { folderId?: number; disableReadOnly?: boolean } = {}
  ): Promise<number> {
    let config: FolderSelectorConfig = {
      initialFolder: options.folderId,
      disableReadOnly: options.disableReadOnly,
    };
    return this.run(() =>
      this._dialog
        .open(CatalogFolderSelectorComponent, { data: config })
        .afterClosed()
        .pipe(filter((v) => v))
        .toPromise()
    );
  }

  addSvgIconSet(url: string) {
    this._matIconRegistry.addSvgIconSet(
      this._sanitizer.bypassSecurityTrustResourceUrl(url)
    );
  }

  addSvgIcon(name: string, html?: string) {
    if (!html) {
      html = name;
      name = Math.random().toString();
    }
    if (html.match(/^\/|^https/)) {
      this._matIconRegistry.addSvgIcon(
        name,
        this._sanitizer.bypassSecurityTrustResourceUrl(html)
      );
    } else {
      this._matIconRegistry.addSvgIconLiteral(
        name,
        this._sanitizer.bypassSecurityTrustHtml(html)
      );
    }
    return name;
  }
}

@Component({
  selector: "app-ui-collection",
  template: `
    <ng-container *ngFor="let item of collection.items">
      <ng-container [ngSwitch]="getUIElementType(item)">
        <button
          *ngSwitchCase="UIElementType.SubMenuTrigger"
          mat-menu-item
          (click)="click(item)"
          [matMenuTriggerFor]="itemsMenu"
        >
          <mat-icon *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
          <span>{{ item.text }}</span>
        </button>
        <button
          *ngSwitchCase="UIElementType.MenuItem"
          mat-menu-item
          (click)="click(item)"
        >
          <mat-icon *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
          <span>{{ item.text }}</span>
        </button>
        <button
          *ngSwitchCase="UIElementType.IconMenuTrigger"
          mat-icon-button
          (click)="click(item)"
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="item.disabled"
          [class]="item.class"
          [matMenuTriggerFor]="itemsMenu"
        >
          <mat-icon [svgIcon]="item.icon"></mat-icon>
        </button>
        <button
          *ngSwitchCase="UIElementType.IconButton"
          mat-icon-button
          (click)="click(item)"
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="item.disabled"
          [class]="item.class"
        >
          <mat-icon [svgIcon]="item.icon"></mat-icon>
        </button>
        <button
          *ngSwitchCase="UIElementType.ColorMenuTrigger"
          mat-raised-button
          (click)="click(item)"
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="item.disabled"
          [class]="item.class"
          [matMenuTriggerFor]="itemsMenu"
        >
          <mat-icon *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
          <span>{{ item.text }}</span>
        </button>
        <button
          *ngSwitchCase="UIElementType.ColorButton"
          mat-raised-button
          (click)="click(item)"
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="item.disabled"
          [class]="item.class"
        >
          <mat-icon *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
          <span>{{ item.text }}</span>
        </button>
        <button
          *ngSwitchCase="UIElementType.ButtonMenuTrigger"
          mat-button
          (click)="click(item)"
          [matTooltip]="item.tooltip"
          [disabled]="item.disabled"
          [class]="item.class"
          [matMenuTriggerFor]="itemsMenu"
        >
          <mat-icon *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
          <span>{{ item.text }}</span>
        </button>
        <button
          *ngSwitchCase="UIElementType.Button"
          mat-button
          (click)="click(item)"
          [matTooltip]="item.tooltip"
          [disabled]="item.disabled"
          [class]="item.class"
        >
          <mat-icon *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
          <span>{{ item.text }}</span>
        </button>
        <hr *ngSwitchCase="UIElementType.Separator" />
      </ng-container>
      <mat-menu #itemsMenu="matMenu" overlapTrigger="false">
        <ng-template matMenuContent>
          <app-ui-collection
            [collection]="item"
            [menu]="true"
          ></app-ui-collection>
        </ng-template>
      </mat-menu>
    </ng-container>
  `,
  styles: [
    `
      button[mat-raised-button] {
        margin-left: 4px;
        margin-right: 4px;
      }
    `,
  ],
})
export class UICollectionComponent {
  @Input() collection: UICollection;
  @Input() menu = false;
  UIElementType = UIElementType;

  constructor(private snack: MatSnackBar) {}

  getUIElementType(item: UIButton): UIElementType | undefined {
    if (!item.visible) {
      return undefined;
    }
    let type = UIElementType;
    let hasChildren = item.items.length;
    if (this.menu) {
      if (hasChildren) {
        return type.SubMenuTrigger;
      }
      if (item.text) {
        return type.MenuItem;
      }
      return type.Separator;
    }
    if (!item.text) {
      if (hasChildren) {
        return type.IconMenuTrigger;
      }
      return type.IconButton;
    }
    if (item.color) {
      if (hasChildren) {
        return type.ColorMenuTrigger;
      }
      return type.ColorButton;
    }
    if (hasChildren) {
      return type.ButtonMenuTrigger;
    }
    return type.Button;
  }

  async click(item: UIButton) {
    try {
      let result = item.click?.();
      if (result && result.then) {
        await result;
      }
    } catch (error) {
      if (!(error instanceof ToolCancelled)) {
        console.error("Error inside click handler:", error);
        if (error) {
          this.snack.open(error.toString());
        }
      }
    }
  }
}

export class SystemInterface {
  constructor(
    private _system: SystemService,
    private _planner: PlannerScriptInterface,
    private _files: FilesService
  ) {}

  async sendEmail(address: string, body: string) {
    return this._system.sendEmail(address, body).toPromise();
  }

  async generateXmlReport(id: number) {
    return this._system
      .getTemplate(id)
      .pipe(concatMap((info) => of(compileXml(info.template, this._planner))))
      .toPromise();
  }

  async uploadModel(
    model: File,
    folderOrFileId: number,
    options: FileUploadOptions
  ): Promise<boolean> {
    await this._files.uploadFile(folderOrFileId, model, options).toPromise();
    return true;
  }
}

export class HttpWrapper {
  constructor(private _http: HttpClient) {}

  get(url: string, options?: any) {
    return this._http.get(url, options).toPromise();
  }

  post(url: string, body: any, options?: any) {
    const proxyPrefix = "proxy://";
    if (url.startsWith(proxyPrefix)) {
      let proxyBody = { url: url.substr(proxyPrefix.length), body };
      return this._http.post("/api/system/proxy", proxyBody).toPromise();
    }
    return this._http.post(url, body, options).toPromise();
  }

  delete(url: string, options?: any) {
    return this._http.delete(url, options).toPromise();
  }
}
