import { environment } from "environments/environment";
import { BrowserModule } from "@angular/platform-browser";
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { RouterModule, Route } from "@angular/router";
import {
  OverlayContainer,
  FullscreenOverlayContainer,
} from "@angular/cdk/overlay";

import { Angulartics2Module } from "angulartics2";
import { AppComponent } from "./app.component";
import { HomepageComponent } from "./homepage/homepage.component";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { ProjectsComponent } from "./planner/projects/projects.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { AuthInterceptor } from "./shared";
import { AdminGuard, AuthStatusGuard, ShortProjectLinkGuard } from "./guards";
import { ProjectEditorModule } from "./planner/planner.module";
import { StoreHomeComponent } from "./store-home/store-home.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ProjectEditorComponent } from "./planner/planner.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { DatePipe } from "@angular/common";
import { SharedModule } from "./shared/shared.module";
import { AppDialogsModule } from "./dialogs/dialogs.module";
import { sentryErrorHandlers, initSentry } from "./sentry";
import { EmbedHomeComponent } from "embed/embed-home/embed-home.component";
import { SystemService } from "./shared/system.service";
import { EmbedService } from "embed/embed.service";

initSentry();

const appRoutes: Route[] = [
  { path: "", pathMatch: "full", component: HomepageComponent },
  { path: "gallery", component: ProjectsComponent, data: { view: 1 } },
  { path: "projects", component: ProjectsComponent, data: { view: 2 } },
  {
    path: "project/:id",
    component: ProjectEditorComponent,
    canActivate: [AuthStatusGuard],
  },
  {
    path: "p/:id",
    component: ProjectEditorComponent,
    canActivate: [ShortProjectLinkGuard],
  },
  {
    path: "catalog",
    loadChildren: () =>
      import("./catalog-editor/catalog-editor.module").then(
        (m) => m.CatalogEditorModule
      ),
    canActivate: [AuthStatusGuard],
    canActivateChild: [AuthStatusGuard],
  },
  { path: "login", component: LoginComponent },
  {
    path: "render",
    loadChildren: () =>
      import("./render/render.module").then((m) => m.RenderModule),
  },
  { path: "register", component: RegisterComponent },
  {
    path: "account",
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AdminGuard],
  },
  { path: "notfound", component: NotFoundComponent },
  { path: "**", component: NotFoundComponent },
];

const embededRoutes: Route[] = [
  { path: "", pathMatch: "full", component: EmbedHomeComponent },
  { path: "editor", component: ProjectEditorComponent },
  { path: "**", redirectTo: "" },
];

const editorRoutes: Route[] = [
  {
    path: "project/:id",
    component: ProjectEditorComponent,
    canActivate: [AuthStatusGuard],
  },
  { path: "**", component: NotFoundComponent },
];

function angularConfig() {
  const init = SystemService.initConfig;
  let routes = appRoutes;
  if (init.mode === "embedded") {
    routes = embededRoutes;
  }
  if (init.mode === "editor") {
    routes = editorRoutes;
  }
  return { routes };
}

const config = angularConfig();

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    NotFoundComponent,
    RegisterComponent,
    LoginComponent,
    StoreHomeComponent,
    EmbedHomeComponent,
  ],
  imports: [
    BrowserModule,
    environment.e2e ? NoopAnimationsModule : BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(config.routes),
    Angulartics2Module.forRoot({ developerMode: !environment.production }),
    SharedModule,
    AppDialogsModule,
    ProjectEditorModule,
  ],
  providers: [
    ...sentryErrorHandlers,
    EmbedService,
    DatePipe,
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
