<div class="explorer" fxLayout="column">

  <mat-nav-list *ngIf="!folder$" class='overflow-auto' fxFlex>
    <ng-container *ngIf="catalogs$ | async; let catalogs">
      <mat-list-item *ngFor="let c of catalogs.my" (click)="selectCatalog(c)">
        <img matListAvatar class="push catalog-image" [src]="c | preview">
        <span class="catalog-name">{{c.name}}</span>
        <mat-icon>keyboard_arrow_right</mat-icon>
      </mat-list-item>
      <mat-divider *ngIf="catalogs.my.length"></mat-divider>
      <mat-list-item *ngFor="let c of catalogs.all" (click)="selectCatalog(c)">
        <img matListAvatar class="push catalog-image" [src]="c | preview">
        <span class="catalog-name">{{c.name}}</span>
        <mat-icon>keyboard_arrow_right</mat-icon>
      </mat-list-item>
    </ng-container>
  </mat-nav-list>


  <ng-container *ngIf="folder$ | async; let folder">
    <div fxLayout="row" fxLayoutAlign="start center" class="push-sm">
      <button mat-button (click)="back()" class="back">
        <mat-icon>reply</mat-icon>
        <span *ngIf="!searchFolderId">{{folder.name}}</span>
      </button>
      <mat-form-field *ngIf="searchFolderId" class="flex-auto block push-left-sm push-right-sm">
        <mat-label i18n>Search models</mat-label>
        <input matInput #searchInput [formControl]="searchTerm">
      </mat-form-field>
      <span *ngIf="!searchFolderId" class="flex-auto"></span>
      <button class="wp-btn-model-search" *ngIf="!searchFolderId" mat-icon-button (click)="searchFilesInCatalog(folder.catalogId, folder.id)">
        <mat-icon>search</mat-icon>
      </button>
      <button *ngIf="recentFolders && recentFolders.length" mat-icon-button [matMenuTriggerFor]="recentMenu" matTooltip="Recent folders" i18n-matTooltip>
        <mat-icon>access_time</mat-icon>
      </button>
    </div>
    <mat-progress-bar *ngIf="!folder.folder" mode="indeterminate"></mat-progress-bar>

    <div class="file-list" fxFlex>
      <div *ngFor="let f of folder.files; trackBy: fileTrackBy"
        [draggable]="!f.folder" (dragstart)="dragDropModel(f, folder)"
        (click)="fileClick(f, folder)" (mouseup)="fileMouseUp($event, f)"
        [class]="f.class" [class.file-item]="f" [class.folder]="f.folder"
        [class.selected]="f.id === activeFileId">
        <div class="image-container">
          <img class="model-image" [src]="f | preview">
        </div>
        <div class="mat-body-1 item-name">{{f.name}}</div>
        <mat-icon *ngIf="f.folder">keyboard_arrow_right</mat-icon>
        <span class="price" *ngIf="f.price">{{f.price}} Р</span>
      </div>
      <ng-container *ngIf="folder.folder && !folder.files?.length" [ngSwitch]="folder.catalogId">
        <div *ngSwitchCase="FolderStatusCode.ServerError" class="pad" i18n>Can't load models from server</div>
        <div *ngSwitchCase="FolderStatusCode.EmptySearch" class="pad" i18n>Enter model name to search</div>
        <div *ngSwitchCase="FolderStatusCode.SearchNotFound" class="pad">
          <span *ngIf="!folder.name" i18n>No models found</span>
          <span *ngIf="folder.name">{{folder.name}}</span>
        </div>
        <div *ngSwitchDefault class="pad" i18n>No items on sale</div>
      </ng-container>
    </div>
  </ng-container>
</div>

<mat-menu #recentMenu="matMenu">
  <ng-template matMenuContent>
    <button *ngFor="let folder of recentFolders" mat-menu-item (click)="selectFolder(folder.id)">{{folder.name}}</button>
  </ng-template>
</mat-menu>
