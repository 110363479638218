    <tr *ngFor="let p of params; trackBy: paramTrackBy" [ngClass]="rowClasses(p)">
        <td class="cell-name">{{p.name}}</td>
        <td>
            <mat-form-field *ngIf="!p.control" [appearance]="fieldAppearance(true)" class="editable">
                <input *ngIf="!p.variants" matInput [value]="p.size | float"
                    (change)="resizeModel(p.name, $event.target.value, p)">
                <mat-select *ngIf="p.variants" [value]="p.size" (selectionChange)="resizeModel(p.name, $event.value, p)"
                    panelClass="wide">
                    <mat-option *ngFor="let v of p.variants" [value]="v.value">{{v.name}}</mat-option>
                </mat-select>
                <span *ngIf="!p.variants" matSuffix i18n>mm</span>
            </mat-form-field>
            <mat-checkbox *ngIf="p.control" [checked]="!!p.size"
                (change)="resizeModel(p.name, $event.checked ? 1 : 0, p)">
            </mat-checkbox>
        </td>
    </tr>

    <ng-container *ngFor="let p of props; trackBy: propertyTrackBy">
        <tr>
            <td class="cell-name">
                <span>{{p.name}}</span>
            </td>
            <td>
                <mat-form-field floatLabel="never">
                    <mat-select [value]="p.value" (selectionChange)="propertyValueChanged(p, $event)" panelClass="wide"
                        placeholder="&#60; Different &#62;" i18n-placeholder>
                        <mat-select-trigger>
                            <img *ngIf="findMaterial(p); let material" [src]="material | thumbnail"
                                class="material-thumbnail push-right-sm">
                            <span>{{p.valueName()}}</span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let variant of p.variants" [value]="variant.id">
                            <img *ngIf="findMaterial(p, variant); let material" [src]="material | thumbnail"
                                class="material-thumbnail push-right-sm">
                            <span>{{variant.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
    </ng-container>
