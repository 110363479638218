import { MouseInfo, CameraTool, ToolCursor } from "./designer-tool";
import { Entity, Designer, EntityFilter, Mesh } from "./designer";
import { locatePoint } from "./snap-locators";
import * as geom from './geometry/geometry';
import { Box, mat4, plane, vec3 } from "./geometry";
import { getEventFullKey } from "app/shared/keyboard";
import { ModelHandler } from "./model-handler";

export type TransformFunctor<T> = (x: T) => T | undefined;
export type ToolFeedback<T> = (param: T, overlay: geom.OverlayContour, scene: SceneOverlay) => void;

export interface PointPickerOptions {
  transform?: (x: Float64Array, state: {snap: boolean}) => Float64Array | undefined,
  feedback?: ToolFeedback<Float64Array>,
  snapDistance?: number;
  snapEdges?: boolean;
  snapBoxes?: boolean;
  // defaults to true
  snapFaces?: boolean | ((m: MouseInfo) => boolean);
  snapFilter?: EntityFilter;
  orthoBasis?: Float64Array,
  orthoAxes?: Float64Array[],
  orthoDistance?: number;
  keyDown?: (shortcut: string, mouse: MouseInfo) => Float64Array;
};

export class SceneOverlay {
  constructor(private root: Entity) {}
  addDimension3P(p1: Float64Array, p2: Float64Array, p3: Float64Array) {
    // convert plain arrays to typed arrays
    p1 = new Float64Array(p1);
    p2 = new Float64Array(p2);
    p3 = new Float64Array(p3);
    let e = this.root.addChild();
    return ModelHandler.initDimension3P(e, p1, p2, p3);
  }

  addBox(size: ArrayLike<number>, color: string, matrix?: Float64Array) {
    let box = new Box();
    if (size.length === 3) {
      box.addPoint(size);
    } else if (size.length === 6) {
      box.set(size);
    } else {
      throw new Error('Invalid size of box');
    }
    let e = this.root.addChild();
    let mesh = new Mesh();
    mesh.catalog = -1;
    mesh.material = color || '#B3D3DD';
    mesh.createBox(box);
    e.meshes = [mesh];
    if (matrix) {
      if (matrix.length === 3) {
        e.matrix = mat4.ffromTranslation(matrix[0], matrix[1], matrix[2]);
      } else if (matrix.length === 16) {
        e.matrix = mat4.fcopy(matrix);
      } else {
        throw new Error('Invalid matrix');
      }
    }
    e.boxChanged();
    e.changed();
    return e;
  }
}

export class PointPicker extends CameraTool<Float64Array> {
  constructor(ds: Designer, message: string,
      private params: PointPickerOptions = {}) {
    super(ds);
    this.hint = message;
    this.cursor = ToolCursor.Pointer;
    this.selectionMode = false;
  }

  private lockInfo?: { pos: Float64Array, mouse: geom.Vector} = null;

  private orthoAlign(point: Float64Array, mouse: MouseInfo) {
    let ray = this.createRay(mouse);
    let bestAxis: Float64Array = undefined;
    let minDist = this.params.orthoDistance || this.options.snapDistance;
    let ps0 = this.ds.toScreen(this.params.orthoBasis);
    let axes = this.params.orthoAxes || [vec3.axisx, vec3.axisy, vec3.axisz];
    for (let axis of axes) {
      let axisEnd = this.ds.toScreen(vec3.fadd(this.params.orthoBasis, axis));
      if (!axisEnd.equals(ps0)) {
        let dist = geom.pointLineDistance(mouse.pos, ps0, geom.subtract(axisEnd, ps0));
        if (dist < minDist) {
          bestAxis = axis;
          minDist = dist;
        }
      }
    }
    if (bestAxis) {
      let perp = vec3.fcross(ray.dir, bestAxis);
      perp = vec3.fcross(ray.dir, perp);
      let perpPlane = plane.createPN(ray.pos, perp);
      let t = plane.rayIntersect(this.params.orthoBasis, bestAxis, perpPlane);
      if (t) {
        point = vec3.fscaleAndAdd(this.params.orthoBasis, bestAxis, t);
      }
    };
    return point;
  }

  private findPoint(mouse: MouseInfo) {
    const distance = this.params.snapDistance || this.options.snapDistance;
    if (this.lockInfo && this.lockInfo.mouse.distanceTo(mouse.pos) < distance * 0.5) {
      return this.lockInfo.pos;
    }
    let point = locatePoint(mouse, this.root, this.ds.root.windowMatrix, {
      distance,
      edges: this.params.snapEdges,
      boxes: this.params.snapBoxes,
      filter: this.params.snapFilter
    });
    let state = { snap: !!point };
    let ray = this.createRay(mouse);
    if (!point) {
      let snapFaces = this.params.snapFaces;
      let doSnap = typeof snapFaces === 'function' ? snapFaces(mouse) : snapFaces !== false;
      if (doSnap && this.intersect(ray)) {
        point = ray.intersectPos;
        if (this.params.orthoBasis) {
          point = this.orthoAlign(point, mouse);
        }
      }
    }
    if (this.params.orthoBasis) {
      let perpPlane = plane.createPN(this.params.orthoBasis, ray.dir);
      if (point && !this.ds.camera.perspective) {
        let viewDir = this.ds.camera.viewDir;
        let t = plane.rayIntersect(point, viewDir, perpPlane);
        vec3.scaleAndAdd(point, point, viewDir, t);
      } else if (!point && ray.intersectPlane(perpPlane)) {
        point = this.orthoAlign(ray.intersectPos, mouse);
      }
    }
    if (point && this.params.transform) {
      point = this.params.transform(point, state);
    }
    return point;
  }

  handleKeyDown(event: KeyboardEvent) {
    if (this.params.keyDown) {
      let shortcut = getEventFullKey(event);
      let pos = this.params.keyDown(shortcut, this.lastMouse);
      if (pos) {
        this.lockInfo = { pos, mouse: this.lastMouse.pos};
        this.updateViewPoint(pos);
        return true;
      }
    } else {
      let mouse = this.lastMouse.clone();
      mouse.ctrl = event.ctrlKey;
      mouse.alt = event.altKey;
      mouse.shift = event.shiftKey;
      this.updateViewPoint(this.findPoint(mouse));
    }
    return false;
  }

  private updateViewPoint(pos?: Float64Array) {
    this.overlay.clear();
    if (pos) {
      pos = pos.slice();
      if (this.params.feedback) {
        this.temp.deleteChildren();
        let scene = new SceneOverlay(this.temp);
        this.params.feedback(pos, this.overlay, scene);
      } else {
        this.overlay.addPoint3(pos);
      }
    }
  }

  protected move(mouse: MouseInfo) {
    super.move(mouse);
    if (!mouse.anyButton && !this.ds.editorActive) {
      let point = this.findPoint(mouse);
      this.updateViewPoint(point);
    }
  }

  protected up(mouse: MouseInfo) {
    super.up(mouse);
    if (!this.moving && mouse.left) {
      let point = this.findPoint(mouse);
      if (point) {
        this.finish(point.slice());
      }
    }
  }
}

export interface EntityPickerOptions {
  transform?: TransformFunctor<Entity>,
  feedback?: ToolFeedback<Entity>,
  // default is true
  selectOnMove?: boolean;
};

export class EntityPicker extends CameraTool<Entity> {
  constructor(ds: Designer, message: string,
      private params: EntityPickerOptions = {}) {
    super(ds);
    this.hint = message;
    this.selectionMode = false;
  }

  get interactive() {
    return this.params.selectOnMove !== false;
  }

  protected finishing() {
    if (this.interactive) {
      this.ds.selection.clear();
    }
    super.finishing();
  }

  private findEntity(mouse: MouseInfo): Entity {
    let ray = this.createRay(mouse);
    ray.selection = true;
    if (this.intersect(ray)) {
      let entity = <Entity>ray.entity;
      if (entity) {
        return this.params.transform ? this.params.transform(entity) : entity;
      }
    }
  }

  protected move(mouse: MouseInfo) {
    super.move(mouse);
    if (!this.moving && !this.ds.editorActive && this.interactive) {
      let e = this.findEntity(mouse);
      if (e) {
        this.ds.selected = e;
      }
    }
  }

  protected up(mouse: MouseInfo) {
    if (this.finished) return;
    super.up(mouse);
    if (!this.moving && !this.ds.editorActive && mouse.left) {
      let e = this.findEntity(mouse);
      if (e) {
        this.finish(e);
      }
    }
  }
}
