import {
  Injectable,
  Provider,
  SkipSelf,
  Optional,
  TemplateRef,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MatDialogConfig,
} from "@angular/material/dialog";
import { ComponentType } from "@angular/cdk/portal";

import { AppAlertDialogComponent } from "../alert-dialog/alert-dialog.component";
import { AppConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { AppPromptDialogComponent } from "../prompt-dialog/prompt-dialog.component";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  AppProgressDialogComponent,
  ProgressFunction,
} from "../app-progress-dialog/app-progress-dialog.component";

export interface CustomButton {
  actionId: string;
  name: string;
}

export interface IDialogConfig extends MatDialogConfig {
  title?: string;
  message: string;
  customButtons?: CustomButton[];
}

export interface IPromptConfig extends IDialogConfig {
  value?: string;
}

@Injectable()
export class DialogService {
  constructor(
    private _dialogService: MatDialog,
    public snackBar: MatSnackBar
  ) {}

  open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: MatDialogConfig<D>
  ): MatDialogRef<T, R> {
    return this._dialogService.open(componentOrTemplateRef, config);
  }

  closeAll(): void {
    this._dialogService.closeAll();
  }

  openAlert(config: IDialogConfig): MatDialogRef<AppAlertDialogComponent> {
    let dialogConfig: MatDialogConfig = this._createConfig(config);
    let dialogRef = this._dialogService.open(
      AppAlertDialogComponent,
      dialogConfig
    );
    let alertDialogComponent: AppAlertDialogComponent =
      dialogRef.componentInstance;
    alertDialogComponent.title = config.title;
    alertDialogComponent.message = config.message;
    return dialogRef;
  }

  openProgress(
    runner: ProgressFunction
  ): MatDialogRef<AppProgressDialogComponent> {
    let dialogConfig: MatDialogConfig = this._createConfig({ message: "" });
    dialogConfig.data = runner;
    let dialogRef = this._dialogService.open(
      AppProgressDialogComponent,
      dialogConfig
    );
    return dialogRef;
  }

  openConfirm(config: IDialogConfig): MatDialogRef<AppConfirmDialogComponent> {
    let dialogConfig: MatDialogConfig = this._createConfig(config);
    dialogConfig.autoFocus = false;

    let dialogRef: MatDialogRef<AppConfirmDialogComponent> =
      this._dialogService.open(AppConfirmDialogComponent, dialogConfig);
    let confirmDialogComponent: AppConfirmDialogComponent =
      dialogRef.componentInstance;
    confirmDialogComponent.title = config.title;
    confirmDialogComponent.message = config.message;
    confirmDialogComponent.customButtons = config.customButtons;
    return dialogRef;
  }

  openPrompt(config: IPromptConfig): MatDialogRef<AppPromptDialogComponent> {
    let dialogConfig: MatDialogConfig = this._createConfig(config);
    let dialogRef: MatDialogRef<AppPromptDialogComponent> =
      this._dialogService.open(AppPromptDialogComponent, dialogConfig);
    let promptDialogComponent: AppPromptDialogComponent =
      dialogRef.componentInstance;
    promptDialogComponent.title = config.title;
    promptDialogComponent.message = config.message;
    promptDialogComponent.value = config.value;
    return dialogRef;
  }

  private _createConfig(config: IDialogConfig): MatDialogConfig {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.width = "400px";
    dialogConfig.autoFocus = false;
    Object.assign(dialogConfig, config);
    return dialogConfig;
  }

  snack(content: string | TemplateRef<any>) {
    if (typeof content === "string") {
      this.snackBar.open(content);
    } else {
      this.snackBar.openFromTemplate(content);
    }
  }

  getErrorMessage(error: unknown) {
    if (error instanceof HttpErrorResponse) {
      return error.message;
    }
    if (error instanceof Error) {
      return error.message;
    }
    return error.toString();
  }

  showError(error: unknown) {
    this.snackBar.open(this.getErrorMessage(error));
  }
}

export function DIALOG_PROVIDER_FACTORY(
  parent: DialogService,
  dialog: MatDialog,
  snack: MatSnackBar
): DialogService {
  return parent || new DialogService(dialog, snack);
}

export const DIALOG_PROVIDER: Provider = {
  // If there is already service available, use that. Otherwise, provide a new one.
  provide: DialogService,
  deps: [
    [new Optional(), new SkipSelf(), DialogService],
    MatDialog,
    MatSnackBar,
  ],
  useFactory: DIALOG_PROVIDER_FACTORY,
};
