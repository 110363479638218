import { assignDefined } from 'app/shared/utils';
import { Designer, Entity, EntityRay } from './designer';
import { vec3, mat4, Rect, Vector } from './geometry';
import { MouseInfo } from './designer-tool';

export interface MousePoint {
  x: number;
  y: number;
}

export type LocateEntityFilter = (Entity, MouseInfo) => boolean;

interface LocateOptionsInternal {
  edges: boolean;
  boxes: boolean;
  filter: LocateEntityFilter;
  distance: number;
  result: Float64Array;
  temp: Float64Array;
  rect: Rect;
}

export type LocateOptions = Partial<LocateOptionsInternal>;

export function locatePoint(
  mouse: MousePoint,
  root: Entity,
  windowCameraMatrix: Float64Array,
  options?: LocateOptions
): Float64Array | undefined {
  // copy options because distance inside can be changed
  let mutOptions: LocateOptionsInternal = {
    edges: true,
    boxes: true,
    filter: () => true,
    distance: 10,
    result: new Float64Array(),
    temp: vec3.make(0, 0, 0),
    rect: new Rect()
  };
  if (options) {
    assignDefined(mutOptions, options);
  }
  locatePointInternal(mouse, root, windowCameraMatrix, mutOptions);
  if (mutOptions.result.length === 3) {
    return mutOptions.result;
  }
}

function locatePointInternal(
  mouse: MousePoint,
  e: Entity,
  windowCameraMatrix: Float64Array,
  options: LocateOptionsInternal
) {
  let box = e.box;
  let rect = options.rect.empty();
  let point = options.temp;
  let visiblePoints = 0;
  for (let i = 0; i < 8; ++i) {
    if (vec3.transformPerspective(point, box.getPoint(i, point), windowCameraMatrix)) {
      rect.addxy(point[0], point[1]);
      visiblePoints++;
    }
  }
  if (visiblePoints === 0) {
    return;
  }
  rect.enlarge(options.distance);
  if (visiblePoints === 8 && !rect.insidexy(mouse.x, mouse.y)) {
    return;
  }

  let filter = options.filter(e, mouse);
  if (filter && e.edges && options.edges) {
    for (let edgeArray of e.edges) {
      for (let i = 2; i < edgeArray.length; i += 3) {
        point[0] = edgeArray[i - 2];
        point[1] = edgeArray[i - 1];
        point[2] = edgeArray[i - 0];
        checkPoint(point, windowCameraMatrix, mouse, options, e);
      }
    }
  }
  if (filter && e.elastic && e.elastic.box && options.boxes) {
    let box = e.elastic.box;
    for (let i = 0; i < 20; ++i) {
      checkPoint(box.getPoint(i, options.temp), windowCameraMatrix, mouse, options, e);
    }
  }
  if (filter !== false && e.children) {
    let childMatrix = mat4.create();
    for (let child of e.children) {
      mat4.multiply(childMatrix, windowCameraMatrix, child.matrix);
      locatePointInternal(mouse, child, childMatrix, options);
    }
  }
}

function createRayByMatrix(ds: Designer, mouse: Vector, transformMatrix: Float64Array): EntityRay {
  let pos1 = vec3.fromValues(
    mouse.x / ds.canvas.clientWidth * 2.0 - 1.0,
    (1.0 - mouse.y / ds.canvas.clientHeight) * 2.0 - 1.0,
    -1.0
  );
  let pos2 = vec3.fromValues(pos1[0], pos1[1], 0.0);
  let invertTransform = mat4.finvert(transformMatrix);
  if (invertTransform) {
    vec3.transformMat4(pos1, pos1, invertTransform);
    vec3.transformMat4(pos2, pos2, invertTransform);
  }

  let ray = new EntityRay();
  vec3.copy(ray.pos, pos1);
  vec3.sub(ray.dir, pos2, pos1);
  vec3.normalize(ray.dir, ray.dir);
  return ray;
}

function isPointVisible(ds: Designer, p: Float64Array) {
  let screenPos = ds.toScreen(p)
  let ray = createRayByMatrix(ds, screenPos, ds.transformMatrix);
  ray.backfaces = true;
  ray.distance = vec3.distance(ray.pos, p);
  vec3.copy(ray.pos, p);
  vec3.negate(ray.dir, ray.dir);
  vec3.add(ray.pos, ray.pos, ray.dir);
  return !ds.intersect(ray);
}

function checkPoint(point: Float64Array, windowCameraMatrix: Float64Array, mouse: MousePoint,
    options: LocateOptionsInternal, e: Entity) {
  let px = point[0];
  let py = point[1];
  let pz = point[2];
  // TODO: we should select topmost points (by z values)
  if (vec3.transformPerspective(point, point, windowCameraMatrix)) {
    let dx = point[0] - mouse.x;
    let dy = point[1] - mouse.y;
    let pointDistance = Math.sqrt(dx * dx + dy * dy);
    if (pointDistance < options.distance) {
      let global = e.toGlobal(vec3.make(px, py, pz));
      if (isPointVisible(e.ds, global)) {
        options.distance = pointDistance;
        options.result = e.toGlobal(vec3.make(px, py, pz));
      }
    }
  }
}
