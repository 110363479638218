import { IProgramInfo, createProgramInfoWithVariables } from "./twgl";
import { mat4, vec3, Box } from "modeler/geometry";
import { MeshBatch, FillOptions } from "./render-scene";
import * as Shaders from './deferred-shaders';
import { RenderFunction, RenderPipeline } from "./pipeline";
import { ShaderProgram } from "./shaders";

export class ShadowMap {

  public get SIZE() {
    return this.state === 512 ? 512 : 1;
  }

  public map: WebGLTexture;
  private state = 512;
  revision = -1;
  used = true;

  constructor(private gl: WebGLRenderingContext, private isLightSource: boolean) {
    this.map = this.createShadowTexture(isLightSource);
  }


  checkStateAndUpdateTexture(newState: number, isCubeTexture = false) {
    if (newState === this.state) {
      return;
    }
    this.state = newState;
    if (this.map) {
      this.destroy();
    }
    switch (this.state) {
      case -1: {
        this.map = this.createShadowTexture(isCubeTexture, new Float32Array([-1]));
        break;
      }
      case 1: {
        this.map = this.createShadowTexture(isCubeTexture, new Float32Array([6.1e37]));
        break;
      }
      default: this.map = this.createShadowTexture(isCubeTexture);
    }
  }

  createShadowTexture(isCubeTexture = false, values?: Float32Array) {
    let gl = this.gl;
    let map = gl.createTexture();
    let target = isCubeTexture ? gl.TEXTURE_CUBE_MAP : gl.TEXTURE_2D;
    gl.bindTexture(target, map);
    let format = gl['R32F'] || gl.RGBA;
    gl.texParameteri(target, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(target, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(target, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(target, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    if (isCubeTexture) {
      for (let i = 0; i < 6; i++) {
        gl.texImage2D(gl.TEXTURE_CUBE_MAP_POSITIVE_X + i, 0, format,
          this.SIZE, this.SIZE, 0, gl['RED'], gl.FLOAT, values || null);
      }
    } else {
      gl.texImage2D(gl.TEXTURE_2D, 0, format,
        this.SIZE, this.SIZE, 0, gl['RED'], gl.FLOAT, values || null);
    }
    gl.bindTexture(target, null);
    return map;
  }

  destroy() {
    this.gl.deleteTexture(this.map);
  }

  renderSidesFromLightSource(render: RenderFunction, pos: Float32Array, projectionMatrix: Float32Array) {
    let gl = this.gl;
    gl.viewport(0, 0, this.SIZE, this.SIZE);
    let cameraMatrix = mat4.createIdentity32();
    let modelViewMatrix = mat4.createIdentity32();

    let renderSide = (side) => {
      gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, side, this.map, 0);
      render(projectionMatrix, cameraMatrix, modelViewMatrix);
    }

    let axisx = vec3.create();
    let orient = (axisz, axisy) => {
      let m = cameraMatrix;
      vec3.cross(axisx, axisy, axisz);
      // x
      m[0] = axisx[0];
      m[1] = axisx[1];
      m[2] = axisx[2];
      m[3] = 0;
      // y
      m[4] = axisy[0];
      m[5] = axisy[1];
      m[6] = axisy[2];
      m[7] = 0;
      // z
      m[8] = axisz[0];
      m[9] = axisz[1];
      m[10] = axisz[2];
      m[11] = 0;
      // shift
      m[12] = pos[0];
      m[13] = pos[1];
      m[14] = pos[2];
      m[15] = 1;
      mat4.invert(modelViewMatrix, m);
    }

    // cubemap sides
    orient([0, 0, -1], [0, -1, 0]);
    renderSide(gl.TEXTURE_CUBE_MAP_POSITIVE_Z);
    orient([0, 0, 1], [0, -1, 0]);
    renderSide(gl.TEXTURE_CUBE_MAP_NEGATIVE_Z);
    orient([-1, 0, 0], [0, -1, 0]);
    renderSide(gl.TEXTURE_CUBE_MAP_POSITIVE_X);
    orient([1, 0, 0], [0, -1, 0]);
    renderSide(gl.TEXTURE_CUBE_MAP_NEGATIVE_X);
    // front & back
    orient([0, -1, 0], [0, 0, 1]);
    renderSide(gl.TEXTURE_CUBE_MAP_POSITIVE_Y);
    orient([0, 1, 0], [0, 0, -1]);
    renderSide(gl.TEXTURE_CUBE_MAP_NEGATIVE_Y);

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }

  renderSunMap(render: RenderFunction, sunViewMatrix: Float32Array) {
    let gl = this.gl;
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      this.map,
      0);
    gl.viewport(0, 0, this.SIZE, this.SIZE);
    render(sunViewMatrix, undefined, undefined);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }
}

export class SceneShadowMaps {
  constructor(private gl: WebGLRenderingContext, private shaderPrecision: string) {
    this.setFrameAndDepthBuffer();
  }

  private frameBuffer: WebGLFramebuffer;
  private maps: { [index: string]: ShadowMap } = {};
  private lightsShadowMaps: WebGLTexture[] = [];
  private sunShadowMap = new ShadowMap(this.gl, false);

  public sunLight = new Float32Array(4);
  public fromSunLightDir = new Float32Array(16);

  private depthBuffer: WebGLRenderbuffer;

  public maxShadowLights = 10;
  public shadowMinZ = 10;
  public shadowMaxZ = 10000;

  private sunShadowMapProgram: IProgramInfo;
  private shadowMapProgram: IProgramInfo;
  private blurShadow: IProgramInfo;
  private blurShadowCube1: IProgramInfo;
  private blurShadowCube2: IProgramInfo;
  private shadowBlurBuffer: WebGLTexture;
  private shadowBlurFBO: WebGLFramebuffer;

  destroy() {
    for (let itemName in this.maps) {
      let item = this.maps[itemName];
      if (item) {
        item.destroy();
      }
    }
    if (this.sunShadowMap.map) {
      this.gl.deleteTexture(this.sunShadowMap.map);
    }
    if (this.shadowBlurBuffer) {
      this.gl.deleteTexture(this.shadowBlurBuffer);
    }
    if (this.frameBuffer) {
      this.gl.deleteFramebuffer(this.frameBuffer);
    }
    if (this.depthBuffer) {
      this.gl.deleteRenderbuffer(this.depthBuffer);
    }
    if (this.shadowBlurFBO) {
      this.gl.deleteFramebuffer(this.shadowBlurFBO);
    }
    if (this.shadowMapProgram) {
      this.gl.deleteProgram(this.shadowMapProgram.program);
    }
    if (this.sunShadowMapProgram) {
      this.gl.deleteProgram(this.sunShadowMapProgram.program);
    }
    if (this.blurShadowCube1) {
      this.gl.deleteProgram(this.blurShadowCube1.program);
    }
    if (this.blurShadowCube2) {
      this.gl.deleteProgram(this.blurShadowCube2.program);
    }
  }

  private setFrameAndDepthBuffer() {
    let gl = this.gl;
    this.depthBuffer = gl.createRenderbuffer();
    this.frameBuffer = gl.createFramebuffer();

    gl.bindRenderbuffer(gl.RENDERBUFFER, this.depthBuffer);
    gl.renderbufferStorage(gl.RENDERBUFFER, gl['DEPTH_COMPONENT32F'] || gl.DEPTH_COMPONENT16, 512, 512);
    gl.bindRenderbuffer(gl.RENDERBUFFER, null);

    gl.bindFramebuffer(gl.FRAMEBUFFER, this.frameBuffer);
    gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, this.depthBuffer);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }

  update(pipeline: RenderPipeline) {
    pipeline.shadows.shadowMaxZ = pipeline.ds.root.box.diagonal;
    this.computeSunShadowMap(pipeline);
    this.lightsShadowMaps = this.computeShadowMaps(pipeline);
  }

  getSunShadowMap() {
    return this.sunShadowMap.map;
  }

  private computeShadowMaps(pipeline: RenderPipeline): WebGLTexture[] {
    let maps = [];
    let projectionMatrix = mat4.perspective(mat4.createIdentity32(), Math.PI / 2, 1.0, this.shadowMinZ, this.shadowMaxZ) as Float32Array;
    for (let light of pipeline.lights) {
      if (light.shadows) {
        let shadowMap = this.maps[light.uid];
        if (!shadowMap) {
          shadowMap = new ShadowMap(this.gl, true);
          this.maps[light.uid] = shadowMap;
        }
        shadowMap.used = true;
        let canUpdate = !pipeline.adaptive || shadowMap.revision === -1;
        if (canUpdate && shadowMap.revision !== pipeline.scene.revision) {
          this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.frameBuffer);
          shadowMap.renderSidesFromLightSource(
            (p, _, m, e) => this.drawShadowMap(pipeline, p, m, false, e),
            light.position, projectionMatrix);
          this.blurShadowMap(pipeline, shadowMap, shadowMap.SIZE);
          shadowMap.revision = pipeline.scene.revision;
        }
        maps.push(shadowMap.map);
        if (maps.length >= this.maxShadowLights) {
          break;
        }
      }
    }
    return maps;
  }

  private computeSunLight(pipeline: RenderPipeline, result: Float32Array) {
    let sun = pipeline.scene.sunLight;
    vec3.copy(result, sun.computePosition());
    vec3.negate(result, result);
    vec3.normalize(result, result);
    result[3] = sun.luminance;
    return result;
  }

  computeSunShadowMap(pipeline: RenderPipeline): WebGLTexture {
    this.sunLight = this.computeSunLight(pipeline, this.sunLight);
    let sunViewMatrix = this.computeSunMatrixAndDir(pipeline, this.sunLight);
    let canUpdate = !pipeline.adaptive || this.sunShadowMap.revision === -1;
    if (canUpdate && this.sunShadowMap.revision !== pipeline.scene.revision) {
      this.sunShadowMap.revision = pipeline.scene.revision;
      let sun = pipeline.scene.sunLight;
      if (!sun.enabled) {
          this.sunShadowMap.checkStateAndUpdateTexture(-1);
      } else if (!sun.shadows) {
          this.sunShadowMap.checkStateAndUpdateTexture(1);
      } else {
        this.sunShadowMap.checkStateAndUpdateTexture(512);
      }
      if (this.sunShadowMap.SIZE === 512) {
        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.frameBuffer);
        this.sunShadowMap.renderSunMap((p, c, m, e) => this.drawShadowMap(pipeline, p, undefined, true, e), sunViewMatrix);
        this.blurSunShadowMap(pipeline, Shaders.blur9);
      }
    }
    return this.sunShadowMap.map;
  }

  private computeSunMatrixAndDir(pipeline: RenderPipeline, sunLight: Float32Array) {
    let fromCameraToDs = mat4.invert(mat4.createIdentity32(), pipeline.modelViewMatrix);
    let boxDs = pipeline.ds.box;
    let sunLightDir = vec3.fcopy(sunLight);
    let sunX = vec3.cross(vec3.create(), sunLightDir, vec3.axisy);
    sunX = vec3.len(sunX) > 0.0001 ? sunX : vec3.cross(vec3.create(), sunLightDir, vec3.axisx);
    vec3.normalize(sunX, sunX);
    let sunY = vec3.fcross(sunX, sunLightDir);
    vec3.normalize(sunY, sunY);
    let boxFromSun = new Box();
    boxFromSun.clear();
    let m = mat4.ftransformation(vec3.origin, sunLightDir, sunY);
    mat4.invert(m, m);
    boxFromSun.addOBB(boxDs, m);
    let orthoX = boxFromSun.sizex / 2;
    let orthoY = boxFromSun.sizey / 2;
    let orthoZ = boxFromSun.sizez / 2;
    let sceneCenter = boxDs.center;
    let viewPoint = vec3.fsub(sceneCenter, vec3.fscale(sunLightDir, orthoZ));
    let sunCameraMatrix = mat4.flookAt(viewPoint, sceneCenter, sunY);
    sunCameraMatrix = mat4.finvert(sunCameraMatrix);
    let projection = mat4.ortho(mat4.create(), -orthoX, orthoX, -orthoY, orthoY, 0, 2 * orthoZ);
    let transform = mat4.fmultiply(projection, sunCameraMatrix);
    let transform32 = mat4.toFloat32(transform);
    this.fromSunLightDir = mat4.multiply(this.fromSunLightDir, transform32, fromCameraToDs);
    vec3.transformVectorMat4(sunLightDir, sunLightDir, pipeline.modelViewMatrix);
    vec3.copy(this.sunLight, sunLightDir);
    return transform32;
  }

  setUniforms(program: IProgramInfo) {
    let uniforms = program.uniformSetters;
    if (uniforms.u_shadowMaps) {
      uniforms.u_shadowMaps(this.lightsShadowMaps);
      uniforms.u_depthValues([-this.shadowMinZ, 1 / (this.shadowMaxZ - this.shadowMinZ)]);
    }
    uniforms.u_sunShadowMap(this.sunShadowMap.map);
    uniforms.u_sunLightDir(this.sunLight);
    uniforms.u_fromSunLightDir(this.fromSunLightDir);
  }

  cleanup() {
    for (let name in this.maps) {
      let sm = this.maps[name];
      if (sm && !sm.used) {
        sm.destroy();
        this.maps[name] = undefined;
      }
    }
  }

  private getShadowMapProgram() {
    if (!this.shadowMapProgram) {
      this.shadowMapProgram = createProgramInfoWithVariables(this.gl, Shaders.shadowMap, this.shaderPrecision);
    }
    return this.shadowMapProgram;
  }

  private getSunShadowMapProgram() {
    if (!this.sunShadowMapProgram) {
      this.sunShadowMapProgram = createProgramInfoWithVariables(this.gl, Shaders.sunShadowMap, this.shaderPrecision);
    }
    return this.sunShadowMapProgram;
  }


  private setSunProgramAndUniforms(pipeline: RenderPipeline, sunViewMatrix: Float32Array) {
    let gl = this.gl;
    let program = this.getSunShadowMapProgram();
    gl.useProgram(program.program);
    pipeline.program = program;
    program.uniformSetters.u_modelViewMatrix(sunViewMatrix);
  }

  private setLightSourceProgramAndUniforms(pipeline: RenderPipeline, projectionMatrix: Float32Array, modelViewMatrix: Float32Array) {
    let gl = this.gl;
    let program = this.getShadowMapProgram();
    gl.useProgram(program.program);
    pipeline.program = program;
    program.uniformSetters.u_depthValues([-this.shadowMinZ, 1 / (this.shadowMaxZ - this.shadowMinZ)]);
    program.uniformSetters.u_projectionMatrix(projectionMatrix);
    program.uniformSetters.u_modelViewMatrix(modelViewMatrix);
  }

  private drawShadowMap(pipeline: RenderPipeline,
    projectionMatrix: Float32Array,
    modelViewMatrix: Float32Array,
    sun: boolean,
    exclude?: MeshBatch,
  ) {
    let gl = this.gl;
    gl.clearColor(1, 1, 1, 1);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    gl.enable(gl.DEPTH_TEST);
    if (sun) {
      this.setSunProgramAndUniforms(pipeline, projectionMatrix);
    } else {
      this.setLightSourceProgramAndUniforms(pipeline, projectionMatrix, modelViewMatrix);
    }
    let fillOptions = new FillOptions();
    fillOptions.withMaterials = false;
    fillOptions.drawTransparent = false;
    fillOptions.drawReflected = true;
    fillOptions.drawHidden = true;
    fillOptions.exclude = exclude;
    pipeline.scene.renderFill(pipeline, fillOptions);
    gl.disable(gl.DEPTH_TEST);
  }


  private blurSunShadowMap(pipeline: RenderPipeline, program: ShaderProgram) {
    let gl = this.gl;
    if (!this.blurShadow) {
      this.blurShadow = createProgramInfoWithVariables(gl, program, this.shaderPrecision);
    }
    if (!this.shadowBlurBuffer) {
      this.shadowBlurBuffer = this.sunShadowMap.createShadowTexture();
    }

    gl.bindFramebuffer(gl.FRAMEBUFFER, this.frameBuffer);
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      this.shadowBlurBuffer,
      0
    );
    gl.disable(gl.DEPTH_TEST);

    pipeline.setProgram(this.blurShadow);
    // can blur from full resolution to half resolution
    this.blurShadow.uniformSetters.u_resolution([this.sunShadowMap.SIZE, this.sunShadowMap.SIZE]);
    this.blurShadow.uniformSetters.u_image(this.sunShadowMap.map);
    this.blurShadow.uniformSetters.u_direction([1, 0]);
    pipeline.scene.drawScreenQuad();
    this.gl.bindTexture(this.gl.TEXTURE_2D, null);
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      this.sunShadowMap.map,
      0
    );
    this.blurShadow.uniformSetters.u_resolution([this.sunShadowMap.SIZE, this.sunShadowMap.SIZE]);
    this.blurShadow.uniformSetters.u_image(this.shadowBlurBuffer);
    this.blurShadow.uniformSetters.u_direction([0, 1]);
    pipeline.scene.drawScreenQuad();
    this.gl.bindTexture(this.gl.TEXTURE_2D, null);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }

  private blurShadowMap(
    pipeline: RenderPipeline,
    shadowMap: ShadowMap,
    size: number,
  ) {
    let gl = this.gl;
    let cubemap = shadowMap.map;
    if (!this.blurShadowCube1 || !this.blurShadowCube2) {
      this.blurShadowCube1 = createProgramInfoWithVariables(this.gl, Shaders.blurShadowCube1, this.shaderPrecision);
      this.blurShadowCube2 = createProgramInfoWithVariables(this.gl, Shaders.blurShadowCube2, this.shaderPrecision);
      if (!this.shadowBlurBuffer) {
        this.shadowBlurBuffer = shadowMap.createShadowTexture();
      }
      this.shadowBlurFBO = gl.createFramebuffer();
    }
    gl.bindFramebuffer(gl.FRAMEBUFFER, this.shadowBlurFBO);
    let drawBuffers = gl['drawBuffers'];
    if (drawBuffers) {
      drawBuffers.call(gl, [gl.COLOR_ATTACHMENT0]);
    }
    gl.viewport(0, 0, size, size);
    gl.disable(gl.DEPTH_TEST);

    let cameraMatrix = mat4.createIdentity32();
    let axisx = vec3.create();
    let orient = (axisz, axisy) => {
      let m = cameraMatrix;
      vec3.cross(axisx, axisz, axisy);
      // x
      m[0] = axisx[0];
      m[1] = axisx[1];
      m[2] = axisx[2];
      // y
      m[4] = axisy[0];
      m[5] = axisy[1];
      m[6] = axisy[2];
      // z
      m[8] = axisz[0];
      m[9] = axisz[1];
      m[10] = axisz[2];
    }

    for (let i = 0; i < 6; i++) {
      gl.framebufferTexture2D(
        gl.FRAMEBUFFER,
        gl.COLOR_ATTACHMENT0,
        gl.TEXTURE_2D,
        this.shadowBlurBuffer,
        0
      );

      pipeline.setProgram(this.blurShadowCube1);
      this.blurShadowCube1.uniformSetters.u_size(size);
      this.blurShadowCube1.uniformSetters.u_image(cubemap);
      switch (i) {
        case 0: orient([1, 0, 0], [0, -1, 0]); break;
        case 1: orient([-1, 0, 0], [0, -1, 0]); break;
        case 2: orient([0, 1, 0], [0, 0, 1]); break;
        case 3: orient([0, -1, 0], [0, 0, -1]); break;
        case 4: orient([0, 0, 1], [0, -1, 0]); break;
        case 5: orient([0, 0, -1], [0, -1, 0]); break;
      }
      this.blurShadowCube1.uniformSetters.u_rotation(cameraMatrix);
      pipeline.scene.drawScreenQuad();
      this.gl.bindTexture(this.gl.TEXTURE_CUBE_MAP, null);

      gl.framebufferTexture2D(
        gl.FRAMEBUFFER,
        gl.COLOR_ATTACHMENT0,
        gl.TEXTURE_CUBE_MAP_POSITIVE_X + i,
        cubemap,
        0
      );
      pipeline.setProgram(this.blurShadowCube2);
      this.blurShadowCube2.uniformSetters.u_image(this.shadowBlurBuffer);
      this.blurShadowCube2.uniformSetters.u_size(size);
      pipeline.scene.drawScreenQuad();
      this.gl.bindTexture(this.gl.TEXTURE_CUBE_MAP, null);
    }
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }
}
