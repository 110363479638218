<!-- DEFINING DEFAULT MODULE WIDE UI BLOCKER (BUSY) FOR PBL-NGRID -->
<div *pblNgridBlockUiDef class="pbl-ngrid-ui-block">
  <mat-spinner></mat-spinner>
</div>

<!-- DEFINING DEFAULT NO DATA TEMPLATE -->
<div *pblNgridNoDataRef class="pbl-ngrid-no-data"><span>No Results</span></div>
<!-- DEFINING DEFAULT NO DATA TEMPLATE -->

<span *pblNgridCellDraggerRef="let ctx" cdkDragRootElementClass="cdk-drag column-reorder-handle" ></span>

<!-- DEFINING DEFAULT ADMIN MODULE WIDE COLUMNS FOR PBL-NGRID -->
<div *pblNgridCellDef="'*'; value as value;">{{value}}</div>
<div *pblNgridCellTypeDef="'date'; value as value;">{{value | date: 'MMM dd, yyyy'}}</div>
<div *pblNgridCellTypeDef="'number'; value as value;">{{value | number: '1.0-2'}}</div>
<div *pblNgridCellTypeDef="'userid'; value as value;"><a [routerLink]="['/admin', 'user', value]">{{value}}</a></div>
<!-- Set's the currency based on a predefined currency in ISO format, provided in the `type.data` property of the column -->
<div *pblNgridCellTypeDef="'datetime'; value as value;">{{value | date: 'MMM dd, yyyy  HH:mm'}}</div>
<div *pblNgridHeaderCellDef="'*'; col as col;">{{col.label}}</div>
<div *pblNgridFooterCellDef="'*'; col as col;">{{col.label}}</div>


<div *pblNgridHeaderCellTypeDef="'drag_and_drop_handle'"></div>

<!-- START RATINGS -->
<div *pblNgridCellTypeDef="'starRatings'; value as value">
  {{ value >= 1 ? '★' : '☆' }}
  {{ value >= 2 ? '★' : '☆' }}
  {{ value >= 3 ? '★' : '☆' }}
  {{ value >= 4 ? '★' : '☆' }}
  {{ value === 5 ? '★' : '☆' }}
</div>

<div *pblNgridCellTypeDef="'progressBar'; value as value; col as col"
     [ngStyle]="col.type.data.style(value)" [style.width]="value + '%'">
  <div>{{ value }}%</div>
</div>
