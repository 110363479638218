import * as twgl from './twgl';
import * as Shaders from './shaders';
import { RenderPipeline } from './pipeline';

export class CommonRenderer {
  constructor(
    private commongl: WebGLRenderingContext,
    protected shaderPrecision: string,
    protected webgl1: boolean) {
  }

  private imageProgram: twgl.IProgramInfo;

  // depth is gl.DEPTH_COMPONENT16 gl.DEPTH_COMPONENT32F
  protected createRenderBuffer(depth?: number, size = 2, values?: ArrayBufferView): WebGLTexture {
    let gl = this.commongl;
    let result = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, result);
    if (depth) {
      gl.texImage2D(
        gl.TEXTURE_2D,
        0,
        depth,
        size,
        size,
        0,
        gl.DEPTH_COMPONENT,
        gl.FLOAT,
        values || null
      );
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    } else {
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    }
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.bindTexture(gl.TEXTURE_2D, null);
    return result;
  }

  static createShadowBufferTexture(gl: WebGLRenderingContext, size: number): WebGLTexture {
    let texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    let format = gl['R32F'] || gl.RGBA;
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      format,
      size,
      size,
      0,
      gl['RED'],
      gl.FLOAT,
      null);
    gl.bindTexture(gl.TEXTURE_2D, null);
    return texture;
  }

  createProgram(
    program: Shaders.ShaderProgram,
    variables?: { [name: string]: any },
    webgl1?: boolean
  ) {
    if (!webgl1) {
      webgl1 = this.webgl1;
    }
    return twgl.createProgramInfoWithVariables(this.commongl, program, this.shaderPrecision, variables, webgl1);
  }

  drawImage(pipeline: RenderPipeline, image: WebGLTexture, size = 256,  alpha = false) {
    if (!this.imageProgram) {
      this.imageProgram = this.createProgram(Shaders.image, undefined, true);
    }
    let canvasHeight = pipeline.viewport.height;
    let canvasWidth = pipeline.viewport.width;
    let imageWidth = 2 * size / canvasWidth;
    let imageHeight = 2 * size / canvasHeight;
    let usize = [imageWidth, imageHeight];
    pipeline.setProgram(this.imageProgram);
    this.imageProgram.uniformSetters.u_image(image);
    this.imageProgram.uniformSetters.u_pos([-1, -1]);
    this.imageProgram.uniformSetters.u_size(usize);
    this.imageProgram.uniformSetters.useAlpha(alpha ? 0 : 1);
    //this.imageProgram.uniformSetters.u_pos([-1, -1]);
    //this.imageProgram.uniformSetters.u_size([2, 2]);
    pipeline.scene.drawScreenQuad();
    this.commongl.bindTexture(this.commongl.TEXTURE_2D, null);
  }

  destroy() {
    if (this.imageProgram) {
      this.commongl.deleteProgram(this.imageProgram.program);
    }
    this.commongl = undefined;
  }
}
