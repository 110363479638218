<section class="wp-seller-selector-section top">
  <div fxLayout.gt-xs="row">
    <div flex class="pad" fxShow.gt-xs class="pad">
      <div class="push-lg">
        <h1 class="md-display-1 push-top-xs push-bottom-sm slogan wp-seller-selector-header" i18n>WebPlanner Store</h1>
        <h2 class="md-headline push-top-xs push-bottom-sm wp-seller-selector-header" i18n>Choose a seller to start</h2>
      </div>
    </div>
  </div>
</section>

<mat-nav-list class="pad">
  <mat-list-item *ngFor="let user of auth.employees" (click)="loginAs(user)">
    <mat-icon mat-list-icon>account_circle</mat-icon>
    <h4 mat-line>{{user.fullName}}</h4>
  </mat-list-item>
</mat-nav-list>

<button mat-raised-button class="exit" i18n (click)="logout()" i18n>EXIT</button>
