<h1 mat-dialog-title *ngIf="title">{{ title }}</h1>
<mat-dialog-content>
  <span class="message">{{ message }}</span>
</mat-dialog-content>

<mat-dialog-actions
  *ngIf="customButtons?.length"
  class="confirm-dialog__custom-buttons"
>
  <div *ngFor="let button of customButtons">
    <button mat-button color="accent" [mat-dialog-close]="button.actionId">
      {{ button.name }}
    </button>
  </div>

  <button mat-button #closeBtn [mat-dialog-close]="false" i18n>CANCEL</button>
</mat-dialog-actions>

<mat-dialog-actions align="end" *ngIf="!customButtons?.length">
  <button
    id="cancel_button"
    mat-button
    #closeBtn
    (keydown.arrowright)="acceptBtn.focus()"
    [mat-dialog-close]="false"
    i18n
  >
    CANCEL
  </button>
  <button
    id="confirm_button"
    mat-button
    color="accent"
    #acceptBtn
    (keydown.arrowleft)="closeBtn.focus()"
    [mat-dialog-close]="true"
    i18n
  >
    OK
  </button>
</mat-dialog-actions>
